
import React, { useState } from "react";
import { Box, Icon, IconButton, Typography, styled } from "@mui/material";
// import Box from "@mui/material/Box";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LaunchButton, NewIcon, TextBold } from "./LaunchInfo.styles";
import FormModal from "../FormModal";

const LaunchInfo = ()=> {
    const [ openForm, setOpenForm ] = useState(false)

    const handleOnClickOpenForm = (event)=> {
        setOpenForm(true)
    }

    const handleCloseModal = ()=> {
        setOpenForm(false)
    }

    return(
        <>
            <LaunchButton onClick={ handleOnClickOpenForm }>
                <NewIcon>
                    New
                </NewIcon>
                Launching on <TextBold>September 1st!</TextBold> Sign up today! <ConfettiIcon src="/logo/celeb_icon.svg" alt="hurray"/>
            <KeyboardArrowRightIcon/>
            </LaunchButton>
            <FormModal
                openForm={ openForm }
                onFormClose={ handleCloseModal }
            />
        </>
        
    )
}

const ConfettiIcon = styled('img')(({ theme }) => ({
    width: "1.25rem",
    height: "1.25rem",

    [theme.breakpoints.down('md')]:{
        width: "0.875rem",
        height: "0.875rem"
    }
}))

export default LaunchInfo