import React, { useState } from "react";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import { COMPANY_SIZE_LIST, formDataInitState, formStatusInitState, INDUSTRY_CATEGORY, ROLE_LIST } from "./FormModal.constants";
import FormTheme from "../../Themes/FormTheme";
import { updateFormData, validateEmail } from "./FormModal.helpers";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";

const FormModal = ({ openForm, onFormClose })=> {
    const [ formData, setFormData ] = useState(formDataInitState) 
    const [ formStatus, setFormStatus] = useState(formStatusInitState)

    const handleTextChange = (event)=> {
        const { name, value } = event.target

        setFormStatus( prev=> ({ ...prev, [name]: {error: false, helperText: null } }))
        setFormData( (prev)=> ({ ...prev, [name]: value }))
    }

    const handleAutoCompleteTextChange = (name, newValue)=> {
        setFormStatus( prev=> ({ ...prev, [name]: {error: false, helperText: null} }))
        setFormData((prev)=> ({ ...prev, [name]: newValue }))
    }

    const handleKeyPressed = (event)=> {
        if(event.key === "Enter"){
            event.preventDefault()
            handleFormSubmit(event)
        }
    }

    const handleFormSubmit = async (event)=> {
        event.preventDefault()

        if(formData.name === "") setFormStatus((prev)=> ({ ...prev, name: { error: true, helperText: "This field is mandatory."}}))
        else if(formData.email === "") setFormStatus((prev)=> ({ ...prev, email: { error: true, helperText: "This field is mandatory" }}))
        else if( !validateEmail( formData.email) ) setFormStatus((prev)=> ({ ...prev, email: { error: true, helperText: "Invalid email format" }}))
        else if(formData.role === "") setFormStatus((prev)=> ({ ...prev, role: { error: true,  helperText: "This field is mandatory" }}))
        else if(formData.companyName === "") setFormStatus((prev)=> ({ ...prev, companyName: { error: true, helperText: "This field is mandatory."}}))
        else if(formData.teamSize === null) setFormStatus((prev)=> ({ ...prev, teamSize: { error: true, helperText: "This field is mandatory" }}))
        else if(formData.industryType === null) setFormStatus((prev)=> ({ ...prev, industryType: { error: true,  helperText: "This field is mandatory" }})) 
 
        else{
            const response = await updateFormData(formData)
            if(response?.status === 200){
                onFormClose()
                setFormData(formDataInitState)
                setFormStatus(formStatusInitState)
            }
        }
    }

    return(
        <ThemeProvider theme={FormTheme}>
            <Dialog
                open={ openForm }
                onClose={ onFormClose }
                PaperProps={{
                    style:{
                        width: "32.75rem",
                        borderRadius: "1rem",
                        scrollbarWidth: "none",
                    }
                }}
            >
                <DialogTitle>
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"start"}
                    >
                        <Box sx={{ width: "2rem"}}/>
                        <Stack flexDirection={"row"} justifyContent={"center"} padding={"0.75rem 0"} >
                            <img src={"/logo/misc/fluent_hand-wave-16-filled.svg"} width={24} height={24} alt="wave-hand"/> 
                            <Typography fontFamily={"Neue Haas Grotesk Display Pro"} fontSize={"1.25rem"} fontWeight={600}>Join 150+ Businesses in the waitlist</Typography>
                        </Stack>

                        <IconButton onClick={ onFormClose }>
                            <CloseOutlined/>
                        </IconButton>
                    </Stack>
                    
                    
                    
                </DialogTitle>
                <DialogContent sx={{ width: "68%", margin: "1.5rem auto 0 auto" }}>
                    <form onSubmit={ handleFormSubmit }>
                        <Stack
                            gap={"1rem"}
                        >
                            <CustomTextField
                                fullWidth
                                variant="filled"
                                name="name"
                                label="Your name"
                                value={ formData?.name }
                                onChange={ handleTextChange }
                                onKeyDown={ handleKeyPressed }
                                error={formStatus.name.error}
                                helperText={formStatus.name.helperText}
                            />
                            <CustomTextField
                                fullWidth
                                variant="filled"
                                name="email"
                                value={ formData?.email }
                                onChange={ handleTextChange }
                                onKeyDown={ handleKeyPressed }
                                label="Your email address"
                                error={formStatus.email.error}
                                helperText={formStatus.email.helperText}
                            />
                            <Autocomplete
                                fullWidth
                                name="role"
                                disablePortal
                                options={ROLE_LIST}
                                value={ formData.role }
                                onChange={ (e, value)=> handleAutoCompleteTextChange("role", value) }
                                onKeyDown={ handleKeyPressed }
                                renderInput={(params)=> (
                                    <CustomTextField 
                                        {...params} 
                                        variant="filled"
                                        label="Select Role"
                                        error={formStatus.role.error}
                                        helperText={formStatus.role.helperText}

                                    />
                                )}
                                ListboxProps={{
                                    style:{
                                        maxHeight: "8rem"
                                    }
                                }}
                            />
                            <CustomTextField
                                fullWidth
                                variant="filled"
                                name="companyName"
                                value={ formData?.companyName }
                                onChange={ handleTextChange }
                                onKeyDown={ handleKeyPressed }
                                label="Company name"
                                error={formStatus.companyName.error}
                                helperText={formStatus.companyName.helperText}
                            />
                            <Autocomplete
                                fullWidth
                                disablePortal
                                name="teamSize"
                                options={COMPANY_SIZE_LIST}
                                value={ formData?.teamSize }
                                onChange={ (e, value)=> handleAutoCompleteTextChange("teamSize", value)  }
                                onKeyDown={ handleKeyPressed }
                                renderInput={(params)=> (
                                    <CustomTextField 
                                        {...params} 
                                        variant="filled"
                                        label="Select team size"
                                        
                                        error={ formStatus?.teamSize.status }
                                        helperText={ formStatus?.teamSize?.helperText }
                                    />
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "8rem"
                                    }
                                }}
                            />
                            <Autocomplete
                                fullWidth
                                name="industryType"
                                options={INDUSTRY_CATEGORY}
                                value={ formData?.industryType }
                                onChange={ (e, value)=> handleAutoCompleteTextChange("industryType", value)  }
                                onKeyDown={ handleKeyPressed }
                                renderInput={(params)=> (
                                    <CustomTextField 
                                        {...params } 
                                        variant="filled"
                                        label="Select industry you work in"
                                        error={ formStatus?.industryType?.status }
                                        helperText={ formStatus?.industryType?.helperText }
                                    />
                                )}
                                ListboxProps={{
                                    style:{
                                        maxHeight: "8rem"
                                    }
                                }}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ margin: "0.5rem auto 2.5rem auto"}}>
                    <Stack flexDirection={"row"} justifyContent={"center"}>
                        <Button 
                            variant="outlined"
                            sx={{
                                borderRadius: "3rem",
                                width: "6.75rem",
                                border: "2px solid #5161FFF2"
                            }}
                            disabled={
                                formData?.name === "" ||
                                formData?.email === "" ||
                                formData?.role === "" ||
                                formData?.companyName === "" ||
                                formData?.teamSize === "" ||
                                formData?.industryType === ""
                            }
                            onClick={ handleFormSubmit }
                        >
                            <Typography fontSize={"0.9375rem"} fontWeight={700}>Submit</Typography>
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

const CustomTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: 8,
      backgroundColor: "transparent",
      border: '2px solid #919EAB52',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        borderColor: "#46A833"
      },
    },
  }));

export default FormModal