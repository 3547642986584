import { Box, styled } from "@mui/material";

export const LaunchButton = styled(Box)(({ theme }) => ({
    width: "25rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    fontSize: "0.875rem",
    fontWeight: 700,
    color: "#212B36",
    margin: "3rem auto",
    border: "2px solid rgba(255, 171, 0, 0.80)",
    borderRadius: "3rem",
    padding: "0.2rem",
    cursor: "pointer",
    ":hover":{
        boxShadow: "0px 4px 4px #FFAB0070", //rgba(255, 171, 0, 0.50)
    },
    [theme.breakpoints.down('md')]:{
        width: "17.5rem",
        fontSize: "0.62rem",
        margin: "2rem auto",
        padding: "0.1rem",
    }
}))

export const NewIcon = styled(Box)(({ theme }) => ({
    width: "1.9rem",
    height: "1.4rem",
    background: "#212B36",
    color: "#ffffff",
    padding: "0.64rem 1rem 0.26rem 1rem",
    borderRadius: "3.4rem",
    fontSize: "0.875rem",
    fontWeight: 700,
    marginRight: "0.5rem",
    [theme.breakpoints.down('md')]:{
        width:"2.125rem",
        height: "1.375rem",
        fontSize: "0.62rem",
        padding: "0.6rem 0.375rem 0 0.375rem",
        borderRadius:"1.38rem",
        marginRight: "0.1rem"
    }
}))

export const TextBold = styled('b')(({ theme }) => ({
    margin: "0 0.2rem",
    fontSize: "",
    fontWeight: 700,
    [ theme.breakpoints.down('md')]:{
        fontSize: "0.62rem"
    }
}))